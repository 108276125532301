const Spinner = () => {
    /* 
     * style definitions of 'container-d422aee5' and
     * 'spinner-bf6c4662' are defined in 'spinner.css'
     */
    return (
        <div className="container-d422aee5">
            <div className="spinner-bf6c4662">
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
            </div>
        </div>
    );
};

export default Spinner;
