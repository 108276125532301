import React, { MouseEvent } from "react";
import { ScrollUpButtonState } from "./ScrollUpButton.state";

import styles from "./ScrollUpButton.module.css";

class ScrollUpButton extends React.Component<any, ScrollUpButtonState> {

    constructor(props: any) {
        super(props);

        this.state = {
            isButtonVisible: false
        };
    }

    scrollUp() {
        if (!document.scrollingElement ||
            document.scrollingElement.scrollTop === 0) { return; }
    
        ScrollUpButton.changeHtmlScrollBehavior("smooth");
    
        document.scrollingElement.scrollTop = 0;
    
        ScrollUpButton.changeHtmlScrollBehavior("auto");
    }

    onWindowScrolled(event: Event) {
        if (!document.scrollingElement) { return; }
    
        const scrollTop = document.scrollingElement.scrollTop;
    
        this.setState({ isButtonVisible: scrollTop > 500 });
    }

    componentDidMount() {
        window.addEventListener("scroll", this.onWindowScrolled.bind(this));
    }

    onButtonClicked(event: MouseEvent) {
        this.scrollUp();
    }

    render() {
        const buttonStyle = this.state.isButtonVisible ? `${styles.button} ${styles.show}` : styles.button;
        
        return (
            <div className={buttonStyle} onClick={this.onButtonClicked.bind(this)}>
                <i className="fas fa-angle-up" />
            </div>
        );
    }

    static changeHtmlScrollBehavior(behavior: string) {
        const html = document.getElementsByTagName("html")[0];
        html.style.scrollBehavior = behavior;
    }
};

export default ScrollUpButton;
