import React, { MouseEvent } from "react";
import { NavigationBarState } from "./NavigationBar.state";

import styles from "./NavigationBar.module.css";
/* import logo from "../../resources/icons/logo.svg"; */

class NavigationBar extends React.Component<any, NavigationBarState> {

    constructor(props: any) {
        super(props);

        this.state = {
            isMenuActive: false,
            makeNavigationBarSticky: false
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", this.onWindowScrolled.bind(this));
    }

    onWindowScrolled(event: Event) {
        if (!document.scrollingElement) { return; }
    
        const scrollTop = document.scrollingElement.scrollTop;
    
        this.setState({ makeNavigationBarSticky: scrollTop > 20 });
    }

    onHamburgerButtonClicked(event: MouseEvent) {
        this.setState({ isMenuActive: !this.state.isMenuActive });
    }

    onMenuItemClicked(event: MouseEvent) {
        const html = document.getElementsByTagName("html")[0];
        html.style.scrollBehavior = "smooth";

        this.setState({ isMenuActive: !this.state.isMenuActive });
    }

    render() {
        const { isMenuActive, makeNavigationBarSticky } = this.state;
        const navigationBarStyle = makeNavigationBarSticky ?
            `${styles.navigationBar} ${styles.sticky}` : styles.navigationBar;
        const menuStyle = isMenuActive ? `${styles.menu} ${styles.active}` : styles.menu;
        const hamburgerIconStyle = `fas fa-bars ${isMenuActive ? styles.active : ""}`;

        return (
            <nav className={navigationBarStyle}>
                <div className={styles.maxWidth}>
                    <div className={styles.logo}>
                        {/* <img src={logo} alt="Logo"/> */}
                        <a href="# ">Belford<span>Blaze</span></a>
                    </div>
                    <ul className={menuStyle}>
                        <li><a href="#home" className={styles.menuButton} onClick={this.onMenuItemClicked.bind(this)}>Home</a></li>
                        <li><a href="#products" className={styles.menuButton} onClick={this.onMenuItemClicked.bind(this)}>Products</a></li>
                        <li><a href="#services" className={styles.menuButton} onClick={this.onMenuItemClicked.bind(this)}>Services</a></li>
                        <li><a href="#about" className={styles.menuButton} onClick={this.onMenuItemClicked.bind(this)}>About</a></li>
                        <li><a href="#contact" className={styles.menuButton} onClick={this.onMenuItemClicked.bind(this)}>Contact</a></li>
                    </ul>
                    <div className={styles.menuButton} onClick={this.onHamburgerButtonClicked.bind(this)}>
                        <i className={hamburgerIconStyle} />
                    </div>
                </div>
            </nav>
        );
    }
};

export default NavigationBar;
