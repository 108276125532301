import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { Spinner } from "./components";

// application root where contents will be
// dynamically rendered by react dom...
const root = document.getElementById("root");
// lazily importing app page...
const Application = lazy(() => import("./pages/application"));
// lazily importing error page...
const Error = lazy(() => import("./pages/error"));

/**
 * This method is executed before initiating
 * render. You should initialize necessary components
 * here before application starts.
 * @returns {Object} Returns an object containing results if any.
 */
const onBeforeRenderAsync = async () => {
    return { isSuccessful: true };
};

onBeforeRenderAsync().then(result => {
    ReactDOM.render(
        <React.StrictMode>
            <BrowserRouter>
                <Suspense fallback={<Spinner />}>
                    { result.isSuccessful && <Application /> }
                    { !result.isSuccessful && <Error statusCode={400} message="Failed to load necessary components." /> }
                </Suspense>
            </BrowserRouter>
        </React.StrictMode>, root
    );
});
