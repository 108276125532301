import "./Footer.module.css";

const Footer = () => {
    const year = new Date().getUTCFullYear();

    return (
        <footer>
            <span>Copyright <span className="far fa-copyright"></span> {year} <a href="#">Belford Blaze Ltd.</a> Ltd. All rights reserved.</span>
        </footer>
    );
};

export default Footer;
