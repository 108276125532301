import { TitleProperties } from "./Title.properties";

import styles from "./Title.module.css";

const Title = (props: TitleProperties) => {
    return (
        <h2 data-content={props.description ?? ""} className={styles.title}>{props.text}</h2>
    );
};

export default Title;
